import styled from "styled-components";
import { componentLevel } from "../../enums/enums";

type InputProps = {
  isLarge?: boolean;
  isInactive?: boolean;
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  opacity?: componentLevel;
};

export const TextInputLabel = styled.label`
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 1rem;
  margin: 1rem;
`;

export const TextInput = styled.input.attrs({ type: "text" })<InputProps>`
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(inputProps: InputProps) =>
    inputProps.isLarge ? "calc(100% - 2rem)" : "auto"};
  padding: ${(inputProps: InputProps) =>
    inputProps.isLarge ? "1rem" : "0.5rem"};
  margin-top: ${(inputProps: InputProps) =>
    inputProps.isLarge ? "1rem" : "0.5rem"};
  margin-bottom: ${(inputProps: InputProps) =>
    inputProps.isLarge ? "1rem" : "0.5rem"};
  margin-left: 0;
  margin-right: 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.15;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px 4px 0 0;
  border: none;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  width: 70%;
`;

const CheckboxIcon = styled.svg`
  fill: none;
  stroke: ${(props) => props.theme.palette.text.primary};
  stroke-width: 2px;
`;
const CheckboxHiddenInput = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxLabel = styled.label`
  margin: 1rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  > span {
    padding-left: 1rem;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledCheckbox = styled.div<InputProps>`
  display: inline-block;
  width: 32px;
  height: 32px;
  background: rgba(
    ${(props: InputProps) => (props.isChecked ? "25,118,210" : "255,255,255")},
    ${(props: InputProps) =>
      props.isChecked ? "1" : props.opacity ? props.opacity : 1}
  );
  border: 2px solid rgb(25, 118, 210);
  border-radius: 100%;
  transition: all 150ms;

  ${CheckboxHiddenInput}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${CheckboxIcon} {
    visibility: ${(props) => (props.isChecked ? "visible" : "hidden")};
  }
`;

export const Checkbox = ({ isChecked, ...props }: InputProps) => (
  <CheckboxContainer>
    <CheckboxLabel htmlFor={props.id}>
      <CheckboxHiddenInput checked={isChecked} {...props} />
      <StyledCheckbox isChecked={isChecked} opacity={props.opacity}>
        <CheckboxIcon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </CheckboxIcon>
      </StyledCheckbox>
      <span>{props.name}</span>
    </CheckboxLabel>
  </CheckboxContainer>
);
