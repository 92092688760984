import styled from "styled-components";
import { componentLevel } from "../../enums/enums";
import { device } from "../../globalVars/vars";

type ButtonProps = {
  primary?: string;
  secondary?: string;
  isLarge?: boolean;
  isInactive?: boolean;
  renderOn?: string;
  opacity?: componentLevel;
};
type ButtonGroupProps = {
  columns?: number;
  spacing?: number;
  flexDirection?: string;
  groupWidth: number;
};
type ToggleContainerProps = {
  darkModeSelected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const SuccessButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.palette.success.dark}${(props) => props.theme.darkElevation.full};
  color: ${(props) => props.theme.palette.success.contrastText}${(props) => props.theme.darkElevation.full};
  font-size: 1rem;
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 2rem;
  @media ${device.laptop} {
    min-width: 10rem;
  }
  justify-content: space-evenly;
  width: ${(props: ButtonProps) =>
    props.isLarge ? "calc(100% - 1rem)" : "auto"};
  margin: ${(props: ButtonProps) => (props.isLarge ? "0 auto" : "0.5rem")};
  > span.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  > span.action {
    visibility: hidden;
    display: none;
    @media ${device.laptop} {
      visibility: visible;
      display: inline;
    }
    margin: 0.5rem;
  }
`;

export const ErrorButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.palette.error.dark}${(props) => props.theme.darkElevation.full};
  color: ${(props) => props.theme.palette.error.contrastText}${(props) => props.theme.darkElevation.full};
  font-size: 1rem;
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 2rem;
  @media ${device.laptop} {
    min-width: 10rem;
  }
  justify-content: space-evenly;
  width: ${(props: ButtonProps) =>
    props.isLarge ? "calc(100% - 1rem)" : "auto"};
  margin: ${(props: ButtonProps) => (props.isLarge ? "0 auto" : "0.5rem")};
  > span.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  > span.action {
    visibility: hidden;
    display: none;
    @media ${device.laptop} {
      visibility: visible;
      display: inline;
    }
    margin: 0.5rem;
  }
`;
export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.primary && !props.isInactive
      ? `${props.theme.palette.info.dark}${props.theme.darkElevation.full}`
      : props.secondary && props.isInactive
      ? `${props.theme.palette.common.white}${props.theme.darkElevation.l1}`
      : "white"};

  color: ${(props) => props.theme.palette.text.primary};
  font-size: 1rem;
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  border-radius: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 2rem;
  @media ${device.laptop} {
    min-width: 10rem;
  }
  justify-content: space-evenly;
  width: ${(props: ButtonProps) =>
    props.isLarge ? "calc(100% - 1rem)" : "auto"};
  margin: ${(props: ButtonProps) => (props.isLarge ? "0 auto" : "0.5rem")};
  > span.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
  > span.action {
    visibility: hidden;
    display: none;
    @media ${device.laptop} {
      visibility: visible;
      display: inline;
    }
    margin: 0.5rem;
  }
`;

export const ButtonGroup = styled.div`
  @media ${device.mobileS} {
    display: flex;
    flex-direction: ${(props: ButtonGroupProps) => props.flexDirection};
    width: ${(props: ButtonGroupProps) => props.groupWidth}%;
    justify-content: space-evenly;
  }
  @media ${device.laptop} {
    display: grid;
    grid-template-columns: repeat(
      ${(props: ButtonGroupProps) => props.columns},
      1fr
    );
    grid-column-gap: ${(props: ButtonGroupProps) =>
      props.spacing ? props.spacing + "rem" : 0};
    margin: 1rem 0;
  }
`;
