import styled from "styled-components";
import { device } from "../../globalVars/vars";
import { IComponent } from "../../interfaces/interfaces";

export const ToDoApp = styled.div<IComponent>`
  width: 50%;
  @media ${device.laptop} {
    width: 75%;
  }
  background: ${(props) => props.theme.palette.background.paper + "1)"};
  margin: 1.5rem 0;
  padding: 1rem 2rem;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  color: ${(props) => props.theme.palette.text.primary};
`;

export const TaskGroup = styled.div<IComponent>`
  background: ${(props) => props.theme.palette.common.white}${(props) => props.theme.darkElevation.l1};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin: 1.5rem 0;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;
export const NewTaskGroup = styled.div<IComponent>`
  background: ${(props) => props.theme.palette.common.white}${(props) => props.theme.darkElevation.l1};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;
export const ToDoItemView = styled.div(({ theme }) => ({
  background: theme.palette.common.white + theme.darkElevation.l2,
  display: "flex",
  "vertical-align": "middle",
  "flex-direction": "row",
  "border-radius": "2rem",
  padding: "0.5rem",
  "box-shadow": "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
  margin: "1rem 0",
}));

export const ToDoItemEdit = styled.div(({ theme }) => ({
  background: theme.palette.common.white + theme.darkElevation.l1,
  display: "flex",
  "vertical-align": "middle",
  "flex-direction": "column",
  "border-radius": "2rem",
  padding: "1rem",
  "box-shadow": theme.shadows[12],
  margin: "1rem 0",
}));

export const Header1 = styled.h1<IComponent>`
  margin-bottom: ${(props) => (props.noBottomMargin ? "0" : "inherit")};
  margin-top: ${(props) => (props.noTopMargin ? "0" : "inherit")};
  text-align: center;
  font-size: 3rem;
`;

export const Header2 = styled.h2<IComponent>`
  margin-bottom: ${(props) => (props.noBottomMargin ? "0" : "inherit")};
  margin-top: ${(props) => (props.noTopMargin ? "0" : "inherit")};
  text-align: center;
  font-size: 2rem;
`;
export const Paragraph = styled.p<IComponent>`
  margin-bottom: ${(props) => (props.noBottomMargin ? "0" : "inherit")};
  margin-top: ${(props) => (props.noTopMargin ? "0" : "inherit")};
  text-align: left;
  font-size: 1.25rem;
`;
