import { useEffect, useState } from "react";
import { getFromLS, setInLS } from "./localstorage";

export const useDarkMode = () => {
  const [darkMode, setdarkMode] = useState(true);
  const [modeDetermined, setModeDetermined] = useState(false);
  const setMode = (mode: boolean, toggled: boolean) => {
    toggled ? setInLS("darkModeUser", mode) : setInLS("darkModeBrowser", mode);
    setdarkMode(mode);
  };

  const toggleDarkMode = () => {
    darkMode === false ? setMode(true, true) : setMode(false, true);
  };

  useEffect(() => {
    const localUserDarkMode = getFromLS("darkModeUser");
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches &&
    !localUserDarkMode
      ? setMode(true, false)
      : localUserDarkMode
      ? setdarkMode(localUserDarkMode)
      : setMode(false, false);
    setModeDetermined(true);
  }, []);

  return [darkMode, toggleDarkMode, modeDetermined];
};
