import { nanoid } from "nanoid";
import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ThemeProvider } from "styled-components";
import { ButtonGroup } from "./components/styledComponents/StyledButtons";
import {
  Header1,
  Header2,
  Paragraph,
  TaskGroup,
  NewTaskGroup,
  ToDoApp,
} from "./components/styledComponents/StyledComponents";
import ToDoFilters from "./components/ToDoFilters";
import ToDoForm from "./components/ToDoForm";
import ToDoItem from "./components/ToDoItem";
import { componentLevel } from "./enums/enums";
import {
  GlobalResets,
  GlobalStyles,
  matrl_dark,
  matrl_light,
} from "./theme/theme";
import { useDarkMode } from "./utils/useDarkMode";
import { BiSun, BiMoon } from "react-icons/bi";
import Switch from "./components/styledComponents/StyledToggleSwitch";

type AppProps = {
  initialTasks: {
    id: string;
    name: string;
    completed: boolean;
  }[];
};

function App({ initialTasks }: AppProps) {
  /*
    STATE
  */
  const [tasks, setTasks] = useState(initialTasks);
  const [filter, setFilter] = useState("Active");

  /* HOOKS */
  const [darkMode, toggledarkMode, modeDetermined] = useDarkMode();

  /* EARLY VARS */

  const filters: { [key: string]: any } = {
    All: () => true,
    Active: (task: { completed: boolean }) => !task.completed,
    Completed: (task: { completed: boolean }) => task.completed,
  };

  const selectedDarkMode = darkMode ? matrl_dark : matrl_light;

  /*
    CRUD FUNCTIONS
  */
  const addTask = (taskName: string) => {
    alert(taskName);
    const newTask = {
      id: `todo-${nanoid()}`,
      name: taskName,
      completed: false,
    };
    setTasks([...tasks, newTask]);
  };

  const editTask = (id: string, newName: string) => {
    const taskList = tasks.map((task) => {
      if (id === task.id) {
        return { ...task, name: newName };
      }
      return task;
    });
    setTasks(taskList);
  };

  const completeTask = (id: string) => {
    const taskList = tasks.map((task) => {
      if (id === task.id) {
        return { ...task, completed: !task.completed };
      }
      return task;
    });
    setTasks(taskList);
  };

  const deleteTask = (id: string) => {
    const taskList = tasks.filter((task) => id !== task.id);
    setTasks(taskList);
  };

  /*
    MAPS
  */

  const FILTERS_MAP = Object.keys(filters).map((name) => (
    <ToDoFilters
      key={name}
      name={name}
      isPressed={name === filter}
      setFilter={setFilter}
    />
  ));

  const TASK_MAP = tasks.filter(filters[filter]).map((task) => (
    <CSSTransition key={task.id} timeout={500} classNames="move">
      <ToDoItem
        id={task.id}
        name={task.name}
        completed={task.completed}
        key={task.id}
        completeTask={completeTask}
        deleteTask={deleteTask}
        editTask={editTask}
      />
    </CSSTransition>
  ));

  /*
    LATE VARS
  */

  const taskCount = `${TASK_MAP.length} ${
    TASK_MAP.length === 1 ? "task" : "tasks"
  } ${filter !== "Completed" ? "remaining" : "completed"}`;

  if (!modeDetermined) {
    return <div />;
  }

  return (
    <ThemeProvider theme={selectedDarkMode}>
      <GlobalResets />
      <GlobalStyles />
      <Switch toggled={darkMode} onChange={toggledarkMode} id="darkMode" />
      <ToDoApp opacity={componentLevel.one}>
        <Header1 noTopMargin={true} opacity={componentLevel.three}>
          Stefans ToDo List
        </Header1>
        <Paragraph opacity={componentLevel.one}>
          Check out the GIT Repo{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/stefanwright1988/React-ToDoApp"
          >
            here
          </a>
        </Paragraph>
        <NewTaskGroup opacity={componentLevel.one}>
          <ToDoForm addTask={addTask} />
        </NewTaskGroup>
        <TaskGroup opacity={componentLevel.one}>
          <Header2
            noTopMargin={true}
            noBottomMargin={true}
            opacity={componentLevel.three}
          >
            {taskCount}
          </Header2>
          <ButtonGroup
            columns={3}
            spacing={2}
            flexDirection="column"
            groupWidth={100}
          >
            {FILTERS_MAP}
          </ButtonGroup>
          <TransitionGroup
            className="todo-list stack-large stack-exception"
            aria-labelledby="list-heading"
          >
            {TASK_MAP}
          </TransitionGroup>
        </TaskGroup>
      </ToDoApp>
    </ThemeProvider>
  );
}

export default App;
