import React, { useState } from "react";
import { componentLevel } from "../enums/enums";
import { Button, ButtonGroup } from "./styledComponents/StyledButtons";
import { Header2 } from "./styledComponents/StyledComponents";
import { TextInput } from "./styledComponents/StyledInputs";

type ToDoFormProps = {
  addTask: Function;
};

function ToDoForm({ addTask }: ToDoFormProps) {
  const [item, setItem] = useState("");
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setItem(e.currentTarget.value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (item.length) {
      alert("Submit Clicked");
      addTask(item);
      setItem("");
    } else {
      alert("Form entry is null");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Header2 opacity={componentLevel.three}>Add a new task</Header2>
      <TextInput
        isLarge={true}
        id="new-todo-input"
        name="text"
        autoComplete="off"
        value={item}
        onChange={handleChange}
        placeholder="New task name"
      />
      <ButtonGroup columns={1} groupWidth={100}>
        <Button
          primary="25,118,210"
          opacity={componentLevel.full}
          isLarge={true}
          type="submit"
        >
          Add
        </Button>
      </ButtonGroup>
    </form>
  );
}

export default ToDoForm;
