import React from "react";
import { componentLevel } from "../enums/enums";
import { Button } from "./styledComponents/StyledButtons";

type ToDoFiltersProps = {
  name: string;
  isPressed: boolean;
  setFilter: Function;
};

function ToDoFilters({ name, isPressed, setFilter }: ToDoFiltersProps) {
  return (
    <Button
      primary="25,118,210"
      opacity={componentLevel.one}
      secondary="255,255,255"
      isInactive={!isPressed}
      aria-pressed={isPressed}
      onClick={() => setFilter(name)}
    >
      <span>{name}</span>
      <span className="visually-hidden"> tasks</span>
    </Button>
  );
}

export default ToDoFilters;
