import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  ErrorButton,
  SuccessButton,
} from "./styledComponents/StyledButtons";
import {
  Checkbox,
  TextInput,
  TextInputLabel,
} from "./styledComponents/StyledInputs";
import {
  ToDoItemEdit,
  ToDoItemView,
} from "./styledComponents/StyledComponents";
import { componentLevel } from "../enums/enums";
import { isMobile, isTablet } from "react-device-detect";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin2Line } from "react-icons/ri";

type ToDoProps = {
  name: string;
  completed: boolean;
  id: string;
  completeTask: Function;
  deleteTask: Function;
  editTask: Function;
};

function ToDoItem({
  name,
  completed,
  id,
  completeTask,
  deleteTask,
  editTask,
}: ToDoProps) {
  /* State */
  const [isEditing, setEditing] = useState(false);
  const [newName, setNewName] = useState("");

  /* Funcs */
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewName(e.currentTarget.value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editTask(id, newName);
    setNewName("");
    setEditing(false);
  };

  /* Templates */
  const viewItem = (
    <ToDoItemView>
      <Checkbox
        isChecked={completed}
        onChange={() => completeTask(id)}
        id={id}
        name={name}
      />
      <ButtonGroup columns={2} spacing={2} flexDirection="row" groupWidth={50}>
        <SuccessButton
          primary="76,175,80"
          opacity={componentLevel.full}
          onClick={() => setEditing(true)}
        >
          <BsPencil /> <span className="action">Edit</span>
          <span className="visually-hidden">{name}</span>
        </SuccessButton>
        <ErrorButton primary="244,67,54" onClick={() => deleteTask(id)}>
          <RiDeleteBin2Line /> <span className="action">Delete</span>
          <span className="visually-hidden">{name}</span>
        </ErrorButton>
      </ButtonGroup>
    </ToDoItemView>
  );
  const editItem = (
    <ToDoItemEdit>
      <form onSubmit={handleSubmit}>
        <div>
          <TextInputLabel className="todo-label" htmlFor={id}>
            New name for {name}
          </TextInputLabel>
          <TextInput
            isLarge={true}
            id={id}
            value={newName}
            onChange={handleChange}
          />
        </div>
        <ButtonGroup
          columns={2}
          spacing={2}
          flexDirection="row"
          groupWidth={100}
        >
          <ErrorButton
            primary="244,67,54"
            type="button"
            onClick={() => setEditing(false)}
          >
            Cancel
            <span className="visually-hidden">renaming {name}</span>
          </ErrorButton>
          <SuccessButton
            primary="76,175,80"
            type="submit"
            className="btn btn__primary todo-edit"
          >
            Save
            <span className="visually-hidden">new name for {name}</span>
          </SuccessButton>
        </ButtonGroup>
      </form>
    </ToDoItemEdit>
  );
  return <>{isEditing ? editItem : viewItem}</>;
}

export default ToDoItem;
