export enum componentLevel {
  one = "0.05",
  two = "0.15",
  three = "0.25",
  four = "0.40",
  five = "0.50",
  full = "1",
}

export enum VARIANT {
  PRIMARY,
  SECONDARY,
}
