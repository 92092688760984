export const setInLS = (k: string, v: any) => {
  window.localStorage.setItem(k, JSON.stringify(v));
};
export const getFromLS = (k: string) => {
  const value = window.localStorage.getItem(k);

  if (value) {
    return JSON.parse(value);
  }
};
