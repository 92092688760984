import React from "react";
import ReactDOM from "react-dom";
import "./todo.css";
import "./global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { nanoid } from "nanoid";
import { ThemeProvider } from "styled-components";
import {
  matrl_dark,
  matrl_light,
  GlobalResets,
  GlobalStyles,
} from "./theme/theme";
import { useDarkMode } from "./utils/useDarkMode";

const INITIAL_TASKS = [
  { id: `todo-${nanoid()}`, name: "Eat", completed: true },
  { id: `todo-${nanoid()}`, name: "Sleep", completed: false },
  { id: `todo-${nanoid()}`, name: "Code", completed: false },
  { id: `todo-${nanoid()}`, name: "Repeat", completed: false },
];

ReactDOM.render(
  <React.StrictMode>
    <App initialTasks={INITIAL_TASKS} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
